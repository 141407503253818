import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Fade from "@mui/material/Fade";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { lazy, Suspense } from "react";
import Lottie from "react-lottie";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import wicon from "./Images/whatsapp.webp";
import ficon from "./Images/form.webp";
import Loader from "./assets/lotties/Loader.json";
import ErrorBoundaryWrapper from "./ErrorBoundry/ErrorBoundry";
import { useState, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { setShowModal } from "./Redux/Cart/CartStore";
import { useDispatch, useSelector } from "react-redux";
import ThankYouUI from "./Components/Home/Components/Thankyou";
import VerifyUI from "./Components/Home/Components/OTP";
import UpdatesUI from "./Components/Updates/Updates";
import CaseStudiesUI from "./Components/CaseStudies/caseStudies";
import ContactUs from "./Components/ContactUs/ContactUs";
import CaseUI from "./Components/CaseStudies/case";
import AboutUs from "./Components/AboutUs/About";
import BlogsUI from "./Components/Blogs/blogs";
import BlogUI from "./Components/Blogs/case";
import { toast, Toaster } from "react-hot-toast";
import { fetchIpAddress } from "./Components/Functions/fetchIPAddress";
import { useNavigate } from "react-router-dom";

const Header = lazy(() => import("./Components/Common/NavBar"));
const Home = lazy(() => import("./Components/Home/Layout"));
const Footer = lazy(() => import("./Components/Common/Footer"));
const FormModal = lazy(() => import("./Components/Utilities/FormModal"));
const AdminUI = lazy(() => import("./Components/Admin/Admin"));
const ProductsUI = lazy(() => import("./Components/Products/Product"));
const OneProductUI = lazy(() => import("./Components/Products/OneProduct"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
    },
  },
});

const LoaderSkeleton = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="grid h-[80vh] grow place-items-center">
      <div>
        <Lottie options={defaultOptions} width={200} />
      </div>
    </div>
  );
};

const App = () => {
  const [showIcon, setShowIcon] = useState(false);
  // const [formOpen, setFormOpen] = useState(false);
  const device = useMediaQuery("(min-width:768px)");
  const formOpen = useSelector((state) => state.cart.showModal);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const location = window.location.pathname;

    // Google Analytics
    if (window.gtag) {
      window.gtag("config", "G-J7MXYYL9MC", {
        page_path: location,
      });
    }

    // Google Tag Manager
    if (window.dataLayer) {
      window.dataLayer.push({ event: "page_view", page_path: location });
    }

    // Facebook Pixel (fbq)
    if (window.fbq) {
      window.fbq("track", "PageView");
    }
  }, [navigate]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = window.innerHeight * (device ? 0.8 : 1.1);
      const scrollPosition = window.scrollY;
      setShowIcon(scrollPosition > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    console.log("called");
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-hidden">
      <div id="recaptcha-container" style={{ opacity: 0 }}></div>
      <Toaster toastOptions={{ duration: 4000 }} />
      <QueryClientProvider client={queryClient}>
        <div
          className="flex min-h-screen flex-col font-poppins"
          style={{ position: "relative" }}
        >
          <Suspense fallback={<LoaderSkeleton />}>
            <ErrorBoundaryWrapper>
              {/* <Router> */}
              {formOpen && (
                <FormModal
                  setFormOpen={() => {
                    dispatch(setShowModal(false));
                  }}
                />
              )}
              <Header />

              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/thank-you" element={<ThankYouUI />}></Route>
                <Route path="/amsan-admin" element={<AdminUI />}></Route>
                <Route path="/verify" element={<VerifyUI />}></Route>
                <Route path="/updates" element={<UpdatesUI />}></Route>
                <Route path="/contact-us" element={<ContactUs />}></Route>
                <Route path="/about-us" element={<AboutUs />}></Route>
                <Route path="/blogs" element={<BlogsUI />}></Route>
                <Route path="/tally-products" element={<ProductsUI />}></Route>
                <Route
                  path="/tally-products/:id"
                  element={<OneProductUI />}
                ></Route>
                <Route path="/case-studies" element={<CaseStudiesUI />}></Route>
                <Route path="/case-studies/:id" element={<CaseUI />}></Route>
                <Route path="/blogs/:id" element={<BlogUI />}></Route>
              </Routes>
              <Footer />
              {showIcon && (
                <img
                  alt="icon"
                  src={ficon}
                  className={`shadow-lg fixed_icon_form ${
                    showIcon ? "visible" : ""
                  }`}
                  onClick={() => {
                    dispatch(setShowModal(true));
                  }}
                  loading="lazy"
                />
              )}
              <img
                alt="icon"
                src={wicon}
                className="fixed_icon_whatsapp shadow-lg"
                onClick={() => {
                  const newTab = window.open(
                    "https://wa.link/2idoy5",
                    "_blank"
                  );
                  newTab.focus();
                }}
                loading="lazy"
              />
              {/* </Router> */}
            </ErrorBoundaryWrapper>
          </Suspense>
        </div>
      </QueryClientProvider>
      <ToastContainer autoClose={1000} hideProgressBar />
      {/* <button onClick={fetchIpAddress}>Get IP Address</button> */}
    </div>
  );
};

function MainApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default MainApp;
